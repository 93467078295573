import type { loader as rootLoader } from "../../root.tsx"

import { useRouteLoaderData } from "@remix-run/react"

export function useRootLoaderData() {
  const rootData = useRouteLoaderData<typeof rootLoader>("root")

  if (!rootData) {
    throw new Error("Root loader data is not available")
  }

  return rootData
}
